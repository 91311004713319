var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "phones-page" },
    [
      _c("virtual-phone-masthead", {
        attrs: { title: "Voicemail" },
        on: { changed: _vm.fetchData },
      }),
      _vm.loaded
        ? _c(
            "b-row",
            { staticClass: "d-flex align-content-start flex-wrap" },
            [
              _vm.selectedPhoneHasVoicemails
                ? [
                    _c(
                      "b-col",
                      {
                        staticClass: "order-md-1",
                        attrs: { md: "8", sm: "12" },
                      },
                      [_c("virtual-phone-voicemails-card")],
                      1
                    ),
                    _c(
                      "b-col",
                      { staticClass: "m-0 p-0", attrs: { md: "4", sm: "12" } },
                      [_c("virtual-phone-voicemails-list")],
                      1
                    ),
                  ]
                : _c("p", [
                    _vm._v(
                      "\n      This number has not received any voicemails yet.\n    "
                    ),
                  ]),
            ],
            2
          )
        : _vm._e(),
      !_vm.arePhonesActive
        ? _c("b-row", [
            _c("p", [_vm._v("There are currently no active phones.")]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }